import { EventModel } from '@bryntum/scheduler';
import { BryntumScheduler } from '@bryntum/scheduler-react';
import { FC, memo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import DatepickerRange from 'components/datepickers/DatepickerRange/DatepickerRange';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useEventsOverlap } from 'components/modules/Project/Cashflow/hooks/useEventsOvelap';
import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import {
  useAddCashflowItem,
  useDeleteCashflowItem,
  useUpdateCashflowItem,
} from 'queries/cashflow';
import { useCurrentProject } from 'queries/project';
import { useGlobalStore } from 'store';
import { getDateString } from 'utils/formatters';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './CashflowEventEditModal.styles';

export interface CashflowEventEditModalProps {
  event: EventModel;
  scheduler: BryntumScheduler;
}

/**
 * We have to subtract 1 day from the end date because the datepicker range component uses a
 * different format than the scheduler
 * Given dates:
    Mon Jan 15 2024 00:00:00 GMT+0100
    Tue Jan 16 2024 00:00:00 GMT+0100
  bryntum will assume it's one day, but datepicker will assume it's 2 days
  for datepicker valid range for 1 day would be:
    Mon Jan 15 2024 00:00:00 GMT+0100
    Mon Jan 15 2024 00:00:00 GMT+0100
 *
 *  */
const DAY_IN_MS = 24 * 60 * 60 * 1000;

const CashflowEventEditModal: FC<CashflowEventEditModalProps> = ({
  event,
  scheduler,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const [isOverlapError, setIsOverlapError] = useState(false);
  const [startDate, setStartDate] = useState(
    new Date(event.startDate ?? Date.now())
  );
  const initialEndDate = new Date(
    new Date(event.endDate).getTime() - DAY_IN_MS ?? Date.now()
  );
  const [endDate, setEndDate] = useState(initialEndDate);
  const [weekend, setWeekend] = useState(!!(event as any).data.weekend);
  const [name, setName] = useField((event as any).data.name ?? '');
  const [hoursPerDay, setHoursPerDay] = useField(
    String((event as any).data.hours ?? 8)
  );
  const currentProject = useCurrentProject();
  const currentVersionId = useCurrentVersionId();
  const { mutateAsync: updateItem } = useUpdateCashflowItem();
  const { mutateAsync: addItem } = useAddCashflowItem();
  const { mutateAsync: deleteItem } = useDeleteCashflowItem();
  const { getIsEventOverlap } = useEventsOverlap();

  const close = () => {
    if ((event as any).isNew) {
      event.remove();
    }
    closeModal('cashflowEventEdit');
  };

  const handleSave = async () => {
    const updateData = {
      name,
      startDate,
      endDate: new Date(endDate.getTime() + DAY_IN_MS),
      hoursPerDay: hoursPerDay ? Number(hoursPerDay) : 0,
    };
    const isOverlap = getIsEventOverlap(
      updateData.startDate,
      updateData.endDate,
      scheduler.instance.events
        .filter(e => e.resourceId === event.resourceId)
        .filter(e => !(e as any).isNew)
        .filter(e => e.id !== event.id)
    );
    const updateDataBE = {
      end_date: getDateString(updateData.endDate),
      start_date: getDateString(updateData.startDate),
      name: updateData.name,
      hours: updateData.hoursPerDay,
      weekend,
    };
    if (currentProject && currentVersionId && !isOverlap) {
      if ((event as any).isNew) {
        await addItem({
          projectId: currentProject.id,
          projectVersionId: currentVersionId,
          item: {
            version_row_id: Number(event.resource.id),
            ...updateDataBE,
          },
        });
      } else {
        await updateItem({
          projectId: currentProject.id,
          projectVersionId: currentVersionId,
          item: {
            id: Number(event.id),
            ...updateDataBE,
          },
        });
      }
      event.set(updateData);
      close();
    }
  };

  const deleteEvent = async () => {
    if (currentProject && currentVersionId) {
      await deleteItem({
        projectId: currentProject.id,
        projectVersionId: currentVersionId,
        itemId: Number(event.id),
      });
      close();
    }
  };

  const onRangeChange = (startDate: Date | null, endDate: Date | null) => {
    if (!startDate || !endDate) return;
    const isOverlap = getIsEventOverlap(
      startDate,
      endDate,
      scheduler.instance.events
        .filter(e => e.resourceId === event.resourceId)
        .filter(e => !(e as any).isNew)
        .filter(e => e.id !== event.id)
    );
    setIsOverlapError(isOverlap);
    if (startDate) {
      setStartDate(startDate);
    }
    if (endDate) {
      setEndDate(endDate);
    }
  };

  return (
    <ConfigModal
      title={!(event as any).isNew ? 'Edit Event' : 'Add Event'}
      color={currentColor}
      onCloseClick={close}
      zeroPadding
    >
      <Styled.Wrapper>
        <Styled.Row>Resource: {event?.resource?.name}</Styled.Row>
        <Styled.Row>
          <Checkbox
            label="Skip Weekends"
            value={!weekend}
            setValue={v => setWeekend(!v)}
            variant="square"
          />
        </Styled.Row>
        <Styled.Row>
          <FormInput
            onChange={setHoursPerDay}
            value={hoursPerDay}
            placeholder="Hours Per Day"
            mask="float"
          />
        </Styled.Row>
        <Styled.Row>
          <DatepickerRange
            onChange={onRangeChange}
            color={currentColor}
            startLabel={'Start Date'}
            endLabel={'End Date'}
            startInitValue={startDate}
            endInitValue={endDate}
            closeOnChange
          />
          {isOverlapError && (
            <Styled.OverlapError>
              Selected date range overlaps with another event.
            </Styled.OverlapError>
          )}
        </Styled.Row>

        <Styled.Row>
          <FormInput onChange={setName} value={name} placeholder="Label" />
        </Styled.Row>
        <Styled.Buttons>
          <Button
            label={!(event as any).isNew ? 'Save' : 'Add'}
            icon={!(event as any).isNew ? 'check' : 'add'}
            color={currentColor}
            onClick={handleSave}
            disabled={isOverlapError}
          />
          {!(event as any).isNew && (
            <Button
              label="Delete"
              color={ColorNames.sunsetOrange}
              icon="close"
              onClick={deleteEvent}
            />
          )}
        </Styled.Buttons>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(CashflowEventEditModal);
