import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { Role } from 'utils/api.types';

import { ADMIN_ALERTS_KEY } from './adminAlerts';
import { useOrganization } from './organizations';

export const ROLES_KEY = 'ROLES';

export const useRoles = (
  variables?: Parameters<typeof getRoles>[0],
  options?: UseQueryOptions<Role[]>
) => {
  const getRoles = useApiStore(s => s.apiClient.getRoles);
  return useQuery({
    queryKey: [ROLES_KEY, variables],
    queryFn: async () => (await getRoles(variables)).data?.rows,
    ...options,
  });
};

export const useProjectRolesVariables = () => {
  const { data: organization } = useOrganization();
  const canUseUnverified = organization?.entity_config?.roles?.allow_unverified;
  return {
    ...(!canUseUnverified && { verified: true }),
  };
};

export const useProjectRoles = (options?: UseQueryOptions<Role[]>) => {
  const variables = useProjectRolesVariables();
  return useRoles(variables, options);
};

export const useAddRole = () => {
  const addRole = useApiStore(s => s.apiClient.addRole);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof addRole>[0]) => addRole(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ROLES_KEY]);
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
      },
    }
  );
};

export const useUpdateRole = (
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof updateRole>>,
    unknown,
    Parameters<typeof updateRole>[0]
  >
) => {
  const updateRole = useApiStore(s => s.apiClient.updateRole);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof updateRole>[0]) => updateRole(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ROLES_KEY]);
      },
      ...options,
    }
  );
};

export const useDeleteRole = () => {
  const deleteRole = useApiStore(s => s.apiClient.deleteRole);
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteRole>[0]) => deleteRole(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ROLES_KEY]);
        queryClient.invalidateQueries(ADMIN_ALERTS_KEY);
      },
    }
  );
};
