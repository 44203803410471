import { rgba } from 'polished';
import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30rem;
  max-width: 1200rem;
`;

export const Row = styled.div``;

export const Description = styled.div`
  color: ${rgba(colors.white, 0.5)};
  ${({ theme: { locale } }) => setTypography('body3', locale)};
  padding-bottom: 40rem;
`;
