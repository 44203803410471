import { FC, memo } from 'react';

import {
  useDeleteProjectTemplate,
  useProjectTemplates,
} from 'queries/projectTemplates';
import { useCopyStore, useGlobalStore } from 'store';
import { ProjectTemplate } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { ColorNames } from 'utils/styles/theme';

import ConfirmModal from '../ConfirmModal/ConfirmModal';

export interface DeleteTemplateConfirmationModalProps {
  templateId: ProjectTemplate['id'];
  onSuccess: () => void;
}

const DeleteTemplateConfirmationModal: FC<
  DeleteTemplateConfirmationModalProps
> = ({ templateId, onSuccess }) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const { mutateAsync: deleteProjectTemplate, isLoading } =
    useDeleteProjectTemplate();
  const { data: projectTemplates } = useProjectTemplates();
  const template = projectTemplates?.find(
    template => template.id === templateId
  );

  const close = () => {
    closeModal('templateDeleteConfirmation');
  };

  const handleConfirmClick = async () => {
    await deleteProjectTemplate(templateId);
    onSuccess();
    close();
  };

  if (!template) {
    return null;
  }

  return (
    <ConfirmModal
      title={copy.app.deleteTemplateConfirmationModal.title}
      description={replaceString(
        copy.app.deleteTemplateConfirmationModal.title,
        '{name}',
        template.name
      )}
      cancelButtonProps={{
        disabled: isLoading,
        label: copy.app.deleteTemplateConfirmationModal.cancelButton,
        icon: 'close',
        color: ColorNames.nandor,
        onClick: close,
      }}
      confirmButtonProps={{
        disabled: isLoading,
        label: copy.app.deleteTemplateConfirmationModal.confirmButton,
        icon: 'check',
        color: currentColor,
        onClick: handleConfirmClick,
        loading: isLoading,
      }}
    />
  );
};

export default memo(DeleteTemplateConfirmationModal);
