import { useCallback } from 'react';

import { useProjectRoles } from 'queries/roles';
import { Role } from 'utils/api.types';

export const useGetRoleCategory = () => {
  const { data: items } = useProjectRoles();

  const getRoleCategory = useCallback(
    (row?: Partial<Pick<Role, 'category' | 'row_no' | 'role_id'>>) => {
      if (row?.category) return row.category;

      if (!items) return '';
      const rowNo = items.find(({ role_id }) => role_id === row?.role_id)
        ?.row_no;
      if (!rowNo) return '';

      return row
        ? [...items]
            .reverse()
            .splice(items.length - rowNo)
            .find(item => item.category)?.category
        : '';
    },
    [items]
  );

  return { getRoleCategory };
};
