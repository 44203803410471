import { Table } from '@tanstack/react-table';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIsFetching } from 'react-query';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import Spinner from '@u9/bob3-shared/lib/components/Spinner/Spinner';
import { useField } from 'components/inputs/hooks/useField';
import SearchInput from 'components/inputs/SearchInput/SearchInput';
import Tabs from 'components/Tabs/Tabs';
import { useOrganization } from 'queries/organizations';
import { PROJECTS_KEY, useCurrentProject } from 'queries/project';
import { useVendorServices } from 'queries/vendorServices';
import { useGlobalStore } from 'store';
import { useCopyStore } from 'store';
import { useCheckAccessRights } from 'utils/accessRights';
import { ProjectRow } from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { sort, sortString } from 'utils/sort';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';
import AddNewRole from './AddNewRole/AddNewRole';
import { useAddProjectRows } from './hooks/useAddProjectRows';
import { useSuggestedItems } from './hooks/useSuggestedItems';

import * as Styled from './AddProjectRows.styles';

export interface AddProjectRowsModalType {
  tableScrollRef?: React.RefObject<HTMLDivElement>;
  table?: Table<ProjectRow>;
  rowNo?: ProjectRow['row_no'];
}

const AddProjectRows = ({
  tableScrollRef,
  table,
  rowNo,
}: AddProjectRowsModalType) => {
  const copy = useCopyStore(s => s.copy);
  const locale = useCopyStore(s => s.locale);
  const currentColor = useGlobalStore(s => s.currentColor);
  const openModal = useGlobalStore(s => s.openModal);
  const currentProject = useCurrentProject();
  const { isLoading: isVendorServicesLoading } = useVendorServices();

  const currentInputEl = useRef<HTMLInputElement>(null);
  const isProjectFetching = useIsFetching([PROJECTS_KEY, currentProject?.id]);
  const [isAddOverlay, setIsAddOverlay] = useState(false);
  const { checkAnyAccessRight } = useCheckAccessRights();
  const { data: organization } = useOrganization();

  const [searchValue, setSearchValue] = useField('');

  const {
    tabItems,
    isRolesLoading,
    addLoading,
    activeTab,
    setActiveTab,
    items,
    numberOfRowsToRender,
    maxNumberOfRows,
    counters,
    resetCounters,
    handleResourceClick,
    editingSkillId,
    handleEditCounter,
    handleCounterChange,
    handleAdd,
    handleClose,
    closing,
    isVendorServiceTab,
    isAnyRowsToAdd,
  } = useAddProjectRows({ searchValue, tableScrollRef, table, rowNo });

  const renderItems = (itemsToRender: typeof items) => {
    const itemsList = sort(itemsToRender, 'label', sortString, 'asc', locale);

    return (
      <>
        {itemsList?.map(item => {
          if (!item.id || !item.label) return null;
          const isActive = !!counters[item.id];

          return (
            <Styled.ResourceItem
              key={item.id + item.label}
              title={item.label}
              data-cy={`skill-${item.label}`}
            >
              <Styled.Counter
                active={isActive}
                activeColor={currentColor}
                onClick={handleEditCounter(String(item.id))}
                data-cy="edit-counter"
              >
                {editingSkillId === String(item.id) ? (
                  <Styled.CounterInput
                    ref={currentInputEl}
                    type="number"
                    value={counters[item.id] || ''}
                    onChange={event =>
                      handleCounterChange(
                        String(item.id),
                        String(Math.round(Number(event.currentTarget.value)))
                      )
                    }
                    onBlur={() => handleEditCounter(null)()}
                  />
                ) : (
                  counters[item.id] ?? '0'
                )}
              </Styled.Counter>
              <Styled.ResourceLabel
                isActive={isActive}
                onClick={handleResourceClick(String(item.id))}
              >
                {item.label}
              </Styled.ResourceLabel>
            </Styled.ResourceItem>
          );
        })}
      </>
    );
  };

  useEffect(() => {
    if (editingSkillId) {
      currentInputEl.current?.focus();
      currentInputEl.current?.select();
    }
  }, [editingSkillId]);

  useEffect(() => {
    if (closing && !isProjectFetching) {
      handleClose();
    }
  }, [closing, handleClose, isProjectFetching]);

  const handleBack = useCallback(() => {
    setIsAddOverlay(false);
  }, []);

  const openRoleModal = useCallback(() => {
    openModal('roleModal');
  }, [openModal]);

  const openVendorServiceModal = useCallback(() => {
    openModal('vendorServiceModal');
  }, [openModal]);

  const activeItemIndex = useMemo(() => {
    return tabItems.findIndex(tab => tab.label === activeTab);
  }, [activeTab, tabItems]);
  const setActiveItemIndex = useCallback(
    (index: number) => {
      setActiveTab(tabItems[index].label);
    },
    [setActiveTab, tabItems]
  );

  useEffect(() => {
    if (activeTab === null && tabItems[0] && !isRolesLoading) {
      setActiveTab(tabItems[0].label);
    }
  }, [activeTab, isRolesLoading, setActiveTab, tabItems]);

  const { suggestedItems } = useSuggestedItems({
    searchValue,
    currentlyVisibleItems: items ?? [],
  });

  const isAdmin = checkAnyAccessRight([
    'administrator',
    'owner',
    'super admin',
  ]);
  const isAllowedToCreateRoles =
    organization?.entity_config?.roles?.allow_unverified;
  const isAllowedToCreateVendorServices =
    organization?.entity_config?.vendor_services?.allow_unverified;
  const isRequestMode =
    (isVendorServiceTab
      ? !isAllowedToCreateVendorServices
      : !isAllowedToCreateRoles) && !isAdmin;

  return (
    <ConfigModal
      onCloseClick={handleClose}
      color={currentColor}
      title={copy.project.resourcesModal.title}
      {...(!isAddOverlay && {
        buttonProps: {
          disabled: isAnyRowsToAdd || addLoading || isProjectFetching > 0,
          label: copy.project.resourcesModal.button,
          loading: addLoading || isProjectFetching > 0,
          icon: 'add',
          onClick: handleAdd,
          dataCy: 'add-project-rows-modal-save',
        },
      })}
      zeroPadding={true}
    >
      <Styled.Wrapper
        maxNumberOfRows={maxNumberOfRows}
        data-cy="add-rows-modal-wrapper"
      >
        {isAddOverlay ? (
          <AddNewRole onBack={handleBack} />
        ) : isRolesLoading || isVendorServicesLoading || !activeTab ? (
          <Styled.Loader data-cy="add-rows-modal-loading">
            <Spinner color={ColorNames.white} />
          </Styled.Loader>
        ) : (
          <>
            <Styled.Toolbar>
              <Styled.Input>
                <SearchInput
                  value={searchValue}
                  onChange={setSearchValue}
                  theme="dark"
                  placeholder={replaceString(
                    copy.app.searchInputPlaceholder,
                    '{name}',
                    copy.app.roles
                  )}
                />
              </Styled.Input>
              <Styled.TopRightButton>
                <Button
                  label={copy.project.resourcesModal.clearSelection}
                  color={ColorNames.nandor}
                  onClick={resetCounters}
                />
              </Styled.TopRightButton>
            </Styled.Toolbar>
            <Styled.Content>
              <Styled.TabsWrapper>
                <Styled.TabsSelector isEmpty={!tabItems.length}>
                  <Tabs
                    items={tabItems}
                    activeItemIndex={activeItemIndex}
                    setActiveItemIndex={setActiveItemIndex}
                  />
                </Styled.TabsSelector>
              </Styled.TabsWrapper>
              <Styled.ItemsOuterWrapper maxNumberOfRows={maxNumberOfRows}>
                {!tabItems.length && !suggestedItems.length ? (
                  <Styled.EmptyLabel>
                    {copy.project.resourcesModal.empty}
                  </Styled.EmptyLabel>
                ) : (
                  <Styled.ItemsWrapper rowsNumber={numberOfRowsToRender}>
                    {renderItems(items)}
                  </Styled.ItemsWrapper>
                )}
                {suggestedItems.length > 0 && searchValue.length >= 3 && (
                  <Styled.SuggestedItemsWrapper>
                    <Styled.SuggestedItemsLabel>
                      {copy.project.resourcesModal.suggestionsLabel}
                    </Styled.SuggestedItemsLabel>
                    <Styled.ItemsWrapper
                      rowsNumber={Math.ceil(suggestedItems.length / 3)}
                    >
                      {renderItems(suggestedItems)}
                    </Styled.ItemsWrapper>
                  </Styled.SuggestedItemsWrapper>
                )}
              </Styled.ItemsOuterWrapper>
            </Styled.Content>
            <Styled.AdditionalButton
              tabIndex={0}
              onClick={
                isVendorServiceTab ? openVendorServiceModal : openRoleModal
              }
              data-cy="add-rows-modal__suggestion-button"
            >
              {isVendorServiceTab
                ? isRequestMode
                  ? copy.project.resourcesModal.suggestMissingVendorService
                  : copy.project.resourcesModal.addVendorService
                : isRequestMode
                ? copy.project.resourcesModal.suggestMissingRole
                : copy.project.resourcesModal.addRole}
            </Styled.AdditionalButton>
          </>
        )}
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default React.memo(AddProjectRows);
