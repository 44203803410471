import { FC, memo, useMemo, useState } from 'react';

import {
  Select,
  SelectItem,
  SelectSeparator,
} from 'components/dropdowns/Select/Select';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useCurrentProject } from 'queries/project';
import {
  useProjectTemplates,
  useSaveAsProjectTemplate,
} from 'queries/projectTemplates';
import { useCopyStore, useGlobalStore } from 'store';
import { ProjectTemplate } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './TemplateSaveModal.styles';

const NEW_TEMPLATE_OPTION = 'NEW_TEMPLATE_OPTION';

export interface TemplateSaveModalProps {}

const TemplateSaveModal: FC<TemplateSaveModalProps> = () => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const copy = useCopyStore(s => s.copy);
  const currentProject = useCurrentProject();
  const currentVersionId = useCurrentVersionId();
  const { mutateAsync: saveAsProjectTemplate, isLoading } =
    useSaveAsProjectTemplate();

  const [selectedTemplate, setSelectedTemplate] = useState<
    ProjectTemplate['id'] | undefined
  >(undefined);
  const nameValidators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
    }),
    [copy.app.validatorMessages.nameRequired]
  );
  const [name, setName, isNameValid, invalidNameMessage] = useField(
    '',
    nameValidators
  );
  const descriptionValidators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
    }),
    [copy.app.validatorMessages.nameRequired]
  );
  const [
    description,
    setDescription,
    isDescriptionValid,
    invalidDescriptionMessage,
  ] = useField('', descriptionValidators);

  const { data: projectTemplates } = useProjectTemplates();

  const close = () => {
    closeModal('templateSave');
  };

  const handleButtonClick = async () => {
    if (
      !isNameValid ||
      !isDescriptionValid ||
      !currentProject?.id ||
      !currentVersionId
    )
      return;

    await saveAsProjectTemplate({
      ...(selectedTemplate ? { project_template_id: selectedTemplate } : {}),
      description,
      name,
      project_id: currentProject.id,
      version_id: currentVersionId,
    });
    close();
  };

  return (
    <ConfigModal
      title={copy.app.templateSaveModal.title}
      color={currentColor}
      onCloseClick={close}
      buttonProps={{
        label: copy.app.templateSaveModal.cta,
        color: currentColor,
        icon: 'check',
        onClick: handleButtonClick,
        disabled: isLoading || !isNameValid || !isDescriptionValid,
        loading: isLoading,
      }}
    >
      <Styled.Wrapper>
        <Styled.Description>
          {copy.app.templateSaveModal.description}
        </Styled.Description>
        <Styled.Row>
          <Select
            key={selectedTemplate}
            label={copy.app.templateSaveModal.overwriteTemplateTrigger}
            value={selectedTemplate ? String(selectedTemplate) : undefined}
            onValueChange={value => {
              if (value === NEW_TEMPLATE_OPTION) {
                setSelectedTemplate(undefined);
              } else {
                setSelectedTemplate(value ? Number(value) : undefined);
                const template = projectTemplates?.find(
                  t => t.id === Number(value)
                );
                if (template) {
                  setName(template.name);
                  setDescription(template.description);
                }
              }
            }}
          >
            <SelectItem value={NEW_TEMPLATE_OPTION}>
              {copy.app.templateSaveModal.newTemplate}
            </SelectItem>
            <SelectSeparator />
            {projectTemplates?.map(template => (
              <SelectItem key={template.id} value={String(template.id)}>
                {template.name}
              </SelectItem>
            ))}
          </Select>
        </Styled.Row>
        <Styled.Row>
          <FormInput
            value={name}
            onChange={setName}
            placeholder={copy.app.templateSaveModal.templateName}
            required
            error={invalidNameMessage}
          />
        </Styled.Row>
        <Styled.Row>
          <FormInput
            value={description}
            onChange={setDescription}
            placeholder={copy.app.templateSaveModal.templateDescription}
            required
            error={invalidDescriptionMessage}
          />
        </Styled.Row>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(TemplateSaveModal);
