import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { focusVisible, hover, setTypography } from 'utils/styles/mixins';
import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  max-width: 1200rem;
`;

export const Panels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 35rem;
`;

export const Panel = styled.button<{ selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border: 1px solid ${rgba(colors.white, 0.2)};
  border-radius: 20rem;
  padding: 20rem;
  gap: 20rem;

  ${({ selected }) =>
    selected
      ? css``
      : hover(css`
          border-color: ${rgba(colors.white, 0.5)};
        `)}

  ${({ selected }) =>
    selected
      ? css`
          border: 1px solid ${colors.white};
          box-shadow: inset 0 0 3px ${colors.white};
        `
      : ''}
`;

export const PanelTitle = styled.div`
  ${({ theme: { locale } }) => setTypography('heading3', locale)};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20rem;
`;

export const PanelDescription = styled.div`
  ${({ theme: { locale } }) => setTypography('body5', locale)};
  color: ${rgba(colors.white, 0.5)};
  display: flex;
  justify-content: flex-start;
`;

export const PanelActions = styled.div``;

export const PanelButton = styled.button`
  padding: 5rem 10rem;
  border: 1px solid ${rgba(colors.white, 0.5)};
  ${({ theme: { locale } }) => setTypography('body4', locale)};
  border-radius: 10rem;

  &:hover {
    /* border: 1px solid ${colors.white}; */
    background-color: ${rgba(colors.white, 0.15)};
  }

  :focus-visible {
    ${focusVisible};
  }
`;

export const PanelTitleText = styled.div`
  flex: 1;
  width: 100%;
  text-align: start;
`;

export const PanelTitleEnd = styled.div``;

export const PanelTitleIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30rem;
  height: 30rem;
  border: 1px solid white;
`;

export const IndicatorIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 20rem;
`;
