import styled from 'styled-components';

import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40rem;
  padding: 80rem;
`;

export const Row = styled.div``;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
`;

export const OverlapError = styled.div`
  color: ${colors.sunsetOrange};
  padding-top: 12rem;
`;
