import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';

import { useApiStore } from 'store';
import { ProjectTemplate } from 'utils/api.types';

import { PROJECTS_KEY } from './project';

export const PROJECT_TEMPLATES_KEY = 'PROJECT_TEMPLATES';

export const useProjectTemplates = (
  options?: UseQueryOptions<ProjectTemplate[]>
) => {
  const getProjectTemplates = useApiStore(s => s.apiClient.getProjectTemplates);
  return useQuery({
    queryKey: [PROJECT_TEMPLATES_KEY],
    queryFn: async () => (await getProjectTemplates()).data,
    ...options,
  });
};

export const useSaveAsProjectTemplate = () => {
  const saveAsProjectTemplate = useApiStore(
    s => s.apiClient.saveAsProjectTemplate
  );
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof saveAsProjectTemplate>[0]) =>
      saveAsProjectTemplate(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECT_TEMPLATES_KEY);
      },
    }
  );
};

export const useLoadFromProjectTemplate = () => {
  const queryClient = useQueryClient();
  const loadFromProjectTemplate = useApiStore(
    s => s.apiClient.loadFromProjectTemplate
  );
  return useMutation(
    (variables: Parameters<typeof loadFromProjectTemplate>[0]) =>
      loadFromProjectTemplate(variables),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          PROJECTS_KEY,
          String(variables.projectId),
          String(variables.versionId),
        ]);
      },
    }
  );
};

export const useDeleteProjectTemplate = () => {
  const deleteProjectTemplate = useApiStore(
    s => s.apiClient.deleteProjectTemplate
  );
  const queryClient = useQueryClient();
  return useMutation(
    (variables: Parameters<typeof deleteProjectTemplate>[0]) =>
      deleteProjectTemplate(variables),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(PROJECT_TEMPLATES_KEY);
      },
    }
  );
};
