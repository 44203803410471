import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIsFetching, useQueryClient } from 'react-query';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import {
  useAddVertical,
  useUpdateVertical,
  VERTICALS_KEY,
} from 'queries/verticals';
import { useCopyStore } from 'store';
import { useGlobalStore } from 'store';
import { Vertical } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './VerticalModal.styles';

export interface VerticalModalProps {
  vertical: Partial<Vertical>;
  onVerticalCreated?: (vertical: Vertical) => void;
}

const VerticalModal = ({ vertical, onVerticalCreated }: VerticalModalProps) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const isEditing = useMemo(() => !!vertical?.id, [vertical]);
  const [closing, setClosing] = useState(false);
  const isFetchingVerticals = useIsFetching(VERTICALS_KEY);
  const queryClient = useQueryClient();

  const { mutateAsync: update, isLoading: updateLoading } = useUpdateVertical();
  const { mutateAsync: add, isLoading: addLoading } = useAddVertical();

  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
    }),
    [copy.app.validatorMessages.nameRequired]
  );
  const [name, setName, isNameValid, nameInvalidMessage] = useField(
    vertical?.name ? vertical.name : '',
    validators
  );

  const [year, setYear] = useField(vertical?.year ? String(vertical.year) : '');

  const isAnyInvalid = useMemo(() => !isNameValid, [isNameValid]);

  const handleEditClick = useCallback(async () => {
    if (vertical.id) {
      await update({
        id: vertical.id,
        name,
        gp_target: 0,
        year: Number(year),
      });
    }

    setClosing(true);
  }, [update, vertical?.id, name, year]);

  const handleAddClick = useCallback(async () => {
    const { data: vertical } = await add({
      name,
      gp_target: 0,
      year: Number(year),
    });

    onVerticalCreated?.(vertical);

    setClosing(true);
  }, [add, name, onVerticalCreated, year]);

  const handleClose = useCallback(() => {
    closeModal('verticalModal');
  }, [closeModal]);

  useEffect(() => {
    if (closing && !isFetchingVerticals) {
      handleClose();
    }
  }, [closing, handleClose, isFetchingVerticals, queryClient]);

  const onYearChange = useCallback(
    (value: string) => {
      setYear(String(Math.min(Number(value), 2050)));
    },
    [setYear]
  );

  const isAnyLoading = useMemo(
    () => addLoading || updateLoading || isFetchingVerticals > 0,
    [addLoading, isFetchingVerticals, updateLoading]
  );

  const {
    title,
    editTitle,
    addButton,
    editButton,
    namePlaceholder,
    yearPlaceholder,
    subText,
  } = copy.admin.verticalModal;

  return (
    <ConfigModal
      title={isEditing ? editTitle : title}
      color={currentColor}
      buttonProps={{
        color: currentColor,
        label: isEditing ? editButton : addButton,
        icon: isEditing ? 'check' : 'add',
        onClick: isEditing ? handleEditClick : handleAddClick,
        disabled: isAnyInvalid || isAnyLoading,
        loading: isAnyLoading,
        dataCy: 'vertical-modal-save',
      }}
      onCloseClick={handleClose}
    >
      <Styled.Wrapper>
        <Styled.Row>
          <FormInput
            value={name}
            onChange={setName}
            placeholder={namePlaceholder}
            required
            error={nameInvalidMessage}
            dataCy="vertical-modal__name"
          />
        </Styled.Row>
        <Styled.Row>
          <FormInput
            value={year === '0' ? '' : year}
            onChange={onYearChange}
            placeholder={yearPlaceholder}
            dataCy="vertical-modal__year"
            mask="integer"
          />
        </Styled.Row>
        <Styled.Info>{subText}</Styled.Info>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default React.memo(VerticalModal);
