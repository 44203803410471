import { rgba } from 'polished';
import styled from 'styled-components';

import { colors } from 'utils/styles/theme';

export const Wrapper = styled.div`
  padding: 80rem;
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;

export const DefaultRow = styled.div`
  padding-left: 20rem;
`;

export const Spacer = styled.div`
  color: ${rgba(colors.white, 0.5)};
  padding-top: 20rem;
`;

export const Buttons = styled.div`
  display: flex;
  gap: 20rem;
  justify-content: flex-end;
  align-items: center;
  padding-top: 120rem;
`;
