import React, { FC, memo } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import { useProjectTemplates } from 'queries/projectTemplates';
import { useProjectRoles } from 'queries/roles';
import { useProjectVendorServicesWithOther } from 'queries/vendorServices';
import { useCopyStore, useGlobalStore } from 'store';
import { ProjectTemplate } from 'utils/api.types';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';
import { DeleteTemplateConfirmationModalProps } from '../DeleteTemplateConfirmationModal/DeleteTemplateConfirmationModal';

import * as Styled from './TemplateDetailsModal.styles';

export interface TemplateDetailsModalProps {
  templateId: ProjectTemplate['id'];
}

const TemplateDetailsModal: FC<TemplateDetailsModalProps> = ({
  templateId,
}) => {
  const copy = useCopyStore(s => s.copy);
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);
  const openModal = useGlobalStore(s => s.openModal);
  const { data: projectTemplates } = useProjectTemplates();

  const { data: roles } = useProjectRoles();
  const { data: vendorServices } = useProjectVendorServicesWithOther();

  const close = () => {
    closeModal('templateDetails');
  };

  const handleDelete = async () => {
    openModal<DeleteTemplateConfirmationModalProps>(
      'templateDeleteConfirmation',
      {
        templateId,
        onSuccess: close,
      }
    );
  };

  const projectTemplate = projectTemplates?.find(
    template => template.id === templateId
  );

  return (
    <ConfigModal
      title={copy.app.templateDetailsModal.title}
      color={currentColor}
      onCloseClick={close}
      zeroPadding
    >
      <Styled.Wrapper>
        <Styled.Items>
          {projectTemplate?.items.map(item => (
            <React.Fragment key={item.row_no}>
              {item.type === 'resource' ? (
                <Styled.DefaultRow>
                  {roles?.find(role => role.role_id === item.role_id)?.role}
                </Styled.DefaultRow>
              ) : item.type === 'vendor_service' ? (
                <Styled.DefaultRow>
                  {
                    vendorServices?.find(
                      vendorService =>
                        vendorService.id === item.vendor_service_id
                    )?.name
                  }
                </Styled.DefaultRow>
              ) : item.type === 'spacer' ? (
                <Styled.Spacer>{item.spacer_name}</Styled.Spacer>
              ) : null}
            </React.Fragment>
          ))}
        </Styled.Items>
        <Styled.Buttons>
          <Button
            label={copy.app.templateDetailsModal.deleteButton}
            icon="close"
            color={ColorNames.sunsetOrange}
            onClick={handleDelete}
          />
          <Button
            label={copy.app.templateDetailsModal.closeButton}
            icon="check"
            color={currentColor}
            onClick={close}
          />
        </Styled.Buttons>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(TemplateDetailsModal);
