import { FC, memo, useState } from 'react';

import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import { useCurrentProject } from 'queries/project';
import {
  useLoadFromProjectTemplate,
  useProjectTemplates,
} from 'queries/projectTemplates';
import { useCopyStore, useGlobalStore } from 'store';
import { useSvgIcon } from 'u9/hooks';
import { ProjectTemplate } from 'utils/api.types';

import ConfigModal from '../ConfigModal/ConfigModal';
import { TemplateDetailsModalProps } from '../TemplateDetailsModal/TemplateDetailsModal';

import * as Styled from './TemplateLoadModal.styles';

export interface TemplateLoadModalProps {}

const TemplateLoadModal: FC<TemplateLoadModalProps> = () => {
  const copy = useCopyStore(s => s.copy);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const { SvgIcon: CheckIcon } = useSvgIcon('check');
  const currentProject = useCurrentProject();
  const currentVersionId = useCurrentVersionId();
  const openModal = useGlobalStore(s => s.openModal);

  const { data: projectTemplates } = useProjectTemplates();
  const { mutateAsync: loadFromProjectTemplate, isLoading } =
    useLoadFromProjectTemplate();

  const [selectedPanel, setSelectedPanel] = useState<
    ProjectTemplate['id'] | null
  >(null);

  const close = () => {
    closeModal('templateLoad');
  };

  const handleConfirm = async () => {
    if (!selectedPanel || !currentProject || !currentVersionId) return;
    await loadFromProjectTemplate({
      projectId: currentProject.id,
      versionId: currentVersionId,
      templateId: selectedPanel,
    });
    close();
  };

  return (
    <ConfigModal
      title={copy.app.loadTemplateModal.title}
      onCloseClick={close}
      color={currentColor}
      buttonProps={{
        label: copy.app.loadTemplateModal.cta,
        disabled: !selectedPanel || isLoading,
        loading: isLoading,
        color: currentColor,
        icon: 'check',
        onClick: handleConfirm,
      }}
    >
      <Styled.Wrapper>
        <Styled.Panels>
          {projectTemplates?.map(template => (
            <Styled.Panel
              key={template.id}
              onClick={() => setSelectedPanel(template.id)}
              selected={selectedPanel === template.id}
            >
              <Styled.PanelTitle>
                <Styled.PanelTitleIndicator>
                  {selectedPanel === template.id ? (
                    <Styled.IndicatorIcon>
                      <CheckIcon />
                    </Styled.IndicatorIcon>
                  ) : (
                    ''
                  )}
                </Styled.PanelTitleIndicator>
                <Styled.PanelTitleText>{template.name}</Styled.PanelTitleText>

                <Styled.PanelTitleEnd>
                  <Styled.PanelButton
                    onClick={event => {
                      event.stopPropagation();
                      openModal<TemplateDetailsModalProps>('templateDetails', {
                        templateId: template.id,
                      });
                    }}
                  >
                    {copy.app.loadTemplateModal.preview}
                  </Styled.PanelButton>
                </Styled.PanelTitleEnd>
              </Styled.PanelTitle>
              <Styled.PanelDescription>
                {template.description}
              </Styled.PanelDescription>
            </Styled.Panel>
          ))}
        </Styled.Panels>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(TemplateLoadModal);
