import React, { useMemo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import { Select, SelectItem } from 'components/dropdowns/Select/Select';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useAddRole, useProjectRoles } from 'queries/roles';
import { useCopyStore, useGlobalStore } from 'store';
import { useSvgIcon } from 'u9/hooks';

import * as Styled from './AddNewRole.styles.ts';

interface AddNewRoleProps {
  onBack: () => void;
}

const AddNewRole: React.FC<AddNewRoleProps> = ({ onBack }) => {
  const { SvgIcon: ArrowLeft } = useSvgIcon('long_arrow_left');
  const currentColor = useGlobalStore(s => s.currentColor);
  const copy = useCopyStore(s => s.copy);
  const [isSuccess, setIsSuccess] = useState(false);
  const { data: items } = useProjectRoles();
  const rolesCategories = items?.filter(role => !role.role_id);
  const { mutateAsync: addRole, isLoading } = useAddRole();

  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.nameRequired,
      },
    }),
    [copy.app.validatorMessages.nameRequired]
  );
  const [name, setName, isNameValid, nameInvalidMessage] = useField(
    '',
    validators
  );
  const [comment, setComment] = useField('');
  const [activeCategoryId, setActiveCategoryId] = useState<string | null>(null);

  const handleSend = async () => {
    const category = rolesCategories?.find(
      ({ row_no }) => row_no === Number(activeCategoryId)
    )?.category;
    if (category) {
      await addRole({
        category,
        role: name,
      });
      setIsSuccess(true);
    }
  };

  return (
    <Styled.Wrapper>
      <Styled.Form>
        <Styled.BackButton
          onClick={onBack}
          tabIndex={0}
          data-cy="role-suggestions__back-button"
        >
          <Styled.ArrowLeftWrapper>
            <ArrowLeft />
          </Styled.ArrowLeftWrapper>
        </Styled.BackButton>
        <Styled.Title>
          {isSuccess
            ? copy.project.rolesSuggestWindow.successTitle
            : copy.project.rolesSuggestWindow.title}
        </Styled.Title>
        {!isSuccess && (
          <>
            <Styled.Row>
              <Styled.RoleNameWrapper>
                <FormInput
                  value={name}
                  onChange={setName}
                  placeholder={copy.project.rolesSuggestWindow.nameLabel}
                  error={!isNameValid ? nameInvalidMessage : ''}
                  required
                  dataCy="role-suggestions__name-field"
                />
              </Styled.RoleNameWrapper>

              <Styled.FieldSpace />
              <Styled.CategoryDropdownWrapper>
                <Select
                  label={copy.project.rolesSuggestWindow.categoryLabel}
                  required
                  dataCy={'role-suggestions__category'}
                  value={activeCategoryId || undefined}
                  onValueChange={setActiveCategoryId}
                >
                  {rolesCategories?.map(role => (
                    <SelectItem value={String(role.row_no)} key={role.row_no}>
                      {role.category}
                    </SelectItem>
                  ))}
                </Select>
              </Styled.CategoryDropdownWrapper>
            </Styled.Row>
            <Styled.Row>
              <Styled.CommentWrapper>
                <FormInput
                  value={comment}
                  onChange={setComment}
                  placeholder={copy.project.rolesSuggestWindow.commentLabel}
                  dataCy="role-suggestions__comment"
                />
              </Styled.CommentWrapper>
            </Styled.Row>
          </>
        )}
      </Styled.Form>
      {!isSuccess && (
        <Styled.ButtonWrapper>
          <Button
            label={copy.project.rolesSuggestWindow.cta}
            icon="check"
            color={currentColor}
            onClick={handleSend}
            dataCy="role-suggestions__cta"
            disabled={isLoading}
            loading={isLoading}
          />
        </Styled.ButtonWrapper>
      )}
    </Styled.Wrapper>
  );
};

export default React.memo(AddNewRole);
