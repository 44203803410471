import { EventModel, EventModelConfig } from '@bryntum/scheduler';
import { areIntervalsOverlapping } from 'date-fns/areIntervalsOverlapping';
import { useCallback } from 'react';

export const useEventsOverlap = () => {
  const getIsEventOverlap = useCallback(
    (
      eventStartDate: Date,
      eventEndDate: Date,
      events: EventModel[] | Partial<EventModelConfig>[]
    ) => {
      const isOverlap = events.some(e => {
        if (!e.startDate || !e.endDate) return false;

        const isOverlap = areIntervalsOverlapping(
          {
            start: eventStartDate,
            end: eventEndDate,
          },
          {
            start: e.startDate,
            end: e.endDate,
          }
        );
        return isOverlap;
      });

      return isOverlap;
    },
    []
  );

  return {
    getIsEventOverlap,
  };
};
